import moment from 'moment';
import {
  CARD_HALF_WIDTH,
  CARD_WIDTH,
  FIREBASE_ERRORS_TO_BLOCK,
  REGEX,
  RESOLUTION_FACTOR
} from './constant';
import hasIn from 'lodash/hasIn';
import isNumber from 'lodash/isNumber';
import * as Sentry from '@sentry/browser';

const formatDate = (date) => {
  const localDate = moment(date).format('MM/DD/YYYY');
  return localDate;
};
export default formatDate;

export const formValidatorRules = {
  required: {
    required: true,
    message: 'This field is required'
  },
  letters: {
    pattern: REGEX.LETTERS,
    message: 'Please use letters only'
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },
  media: {
    required: true,
    message: 'Please upload media file'
  },
  name: {
    pattern: REGEX.NAME,
    message: 'Please use letters, special characters or spaces'
  },
  zipcode: {
    pattern: REGEX.ZIPCODE,
    message: 'Please use letters, numbers or spaces'
  },
  phoneNumber: {
    pattern: REGEX.PHONE,
    message: 'Please use numbers, dashes, and periods.'
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !/^\d+$/.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please use only numbers or +');
      }
      return Promise.resolve();
    }
  })
};

export const checkFieldInObject = (front, back, fieldName, currentTab) => {
  if (currentTab === 'front') {
    return hasIn(front, fieldName);
  }
  return hasIn(back, fieldName);
};

export const getAllCoordinates = (side, setSide, field, currentTab, x, y) => {
  const fieldElement = document?.getElementById(field);
  let card;
  if (currentTab === 'front') {
    card = document?.getElementById('front-inner');
  } else {
    card = document?.getElementById('back-inner');
  }
  const fieldPosition = fieldElement?.getBoundingClientRect();
  const cardPosition = card?.getBoundingClientRect();
  const topLeftX = fieldPosition?.left - cardPosition?.left;
  const topLeftY = fieldPosition?.top - cardPosition?.top;
  const bottomLeftX = fieldPosition?.left - cardPosition?.left;
  const bottomLeftY = fieldPosition?.bottom - cardPosition?.top;
  const topRightX =
    fieldPosition?.left - cardPosition?.left + fieldPosition?.width;
  const topRightY = fieldPosition?.top - cardPosition?.top;
  const bottomRightX =
    fieldPosition?.left - cardPosition?.left + fieldPosition?.width;
  const bottomRightY =
    fieldPosition?.top - cardPosition?.top + fieldPosition?.height;

  if (!isNumber(x)) {
    const textField = field.split('-')[0];
    const fieldLength = topRightX - topLeftX;
    if (side[textField]?.alignment === 'CENTER') {
      x = CARD_HALF_WIDTH - fieldLength / 2;
    } else {
      x = CARD_WIDTH - fieldLength;
    }
  }
  const obj = {
    topLeft: {
      x: topLeftX * RESOLUTION_FACTOR,
      y: topLeftY * RESOLUTION_FACTOR
    },
    bottomLeft: {
      x: bottomLeftX * RESOLUTION_FACTOR,
      y: bottomLeftY * RESOLUTION_FACTOR
    },
    topRight: {
      x: topRightX * RESOLUTION_FACTOR,
      y: topRightY * RESOLUTION_FACTOR
    },
    bottomRight: {
      x: bottomRightX * RESOLUTION_FACTOR,
      y: bottomRightY * RESOLUTION_FACTOR
    },
    x: x * RESOLUTION_FACTOR,
    y: y * RESOLUTION_FACTOR
  };
  if (fieldPosition) {
    switch (field) {
      case 'customField-front':
      case 'customField-back':
        return setSide({
          ...side,
          customField: {
            ...side?.customField,
            ...obj
          }
        });
      case 'fullName-front':
      case 'fullName-back':
        return setSide({
          ...side,
          fullName: {
            ...side?.fullName,
            ...obj
          }
        });

      case 'firstName-front':
      case 'firstName-back':
        return setSide({
          ...side,
          firstName: {
            ...side?.firstName,
            ...obj
          }
        });
      case 'lastName-front':
      case 'lastName-back':
        return setSide({
          ...side,
          lastName: {
            ...side?.lastName,
            ...obj
          }
        });
      case 'email-front':
      case 'email-back':
        return setSide({
          ...side,
          email: {
            ...side?.email,
            ...obj
          }
        });
      case 'linkedIn-front':
      case 'linkedIn-back':
        return setSide({
          ...side,
          linkedIn: {
            ...side?.linkedIn,
            ...obj
          }
        });
      case 'mobilePhone-front':
      case 'mobilePhone-back':
        return setSide({
          ...side,
          mobilePhone: {
            ...side?.mobilePhone,
            ...obj
          }
        });
      case 'mobilePhoneNumber-front':
      case 'mobilePhoneNumber-back':
        return setSide({
          ...side,
          mobilePhoneNumber: {
            ...side?.mobilePhoneNumber,
            ...obj
          }
        });
      case 'workPhone-front':
      case 'workPhone-back':
        return setSide({
          ...side,
          workPhone: {
            ...side?.workPhone,
            ...obj
          }
        });
      case 'workPhoneNumber-front':
      case 'workPhoneNumber-back':
        return setSide({
          ...side,
          workPhoneNumber: {
            ...side?.workPhoneNumber,
            ...obj
          }
        });
      case 'company-front':
      case 'company-back':
        return setSide({
          ...side,
          company: {
            ...side?.company,
            ...obj
          }
        });
      case 'title-front':
      case 'title-back':
        return setSide({
          ...side,
          title: {
            ...side?.title,
            ...obj
          }
        });
      case 'addressLine1-front':
      case 'addressLine1-back':
        return setSide({
          ...side,
          addressLine1: {
            ...side?.addressLine1,
            ...obj
          }
        });
      case 'addressLine2-front':
      case 'addressLine2-back':
        return setSide({
          ...side,
          addressLine2: {
            ...side?.addressLine2,
            ...obj
          }
        });
      case 'city-front':
      case 'city-back':
        return setSide({
          ...side,
          city: {
            ...side?.city,
            ...obj
          }
        });
      case 'state-front':
      case 'state-back':
        return setSide({
          ...side,
          state: {
            ...side?.state,
            ...obj
          }
        });
      case 'zipcode-front':
      case 'zipcode-back':
        return setSide({
          ...side,
          zipcode: {
            ...side?.zipcode,
            ...obj
          }
        });
      case 'country-front':
      case 'country-back':
        return setSide({
          ...side,
          country: {
            ...side?.country,
            ...obj
          }
        });
      case 'cityStateZipCode-front':
      case 'cityStateZipCode-back':
        return setSide({
          ...side,
          cityStateZipCode: {
            ...side?.cityStateZipCode,
            ...obj
          }
        });
      default:
        break;
    }
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export const catchErrorInSentry = (error) => {
  if (!FIREBASE_ERRORS_TO_BLOCK?.includes(error?.message)) {
    Sentry.captureException(new Error(`Message: ${error}`));
  }
};

export const formOrderNumber = (isSendCloudUser, orderNumber, data) => {
  if (isSendCloudUser) {
    orderNumber = `M${orderNumber} ${
      data?.shippingAddress?.order_number
        ? `(${data?.shippingAddress?.order_number})`
        : ''
    }`;
    return orderNumber;
  } else {
    orderNumber = `M${orderNumber}`;
    return orderNumber;
  }
};

export const getCardStatus = (
  cardType,
  cardGenerationStatus,
  aiConversionStatus,
  cardGenerationCount = 0,
  totalQuantity
) => {
  switch (cardType) {
    case 'Mobilo Card':
      break;
    case 'Mobilo Card (custom)':
      switch (cardGenerationStatus) {
        case 'COMPLETED':
          return 'Completed';
        case 'PROCESSING':
          return `${cardGenerationCount}/${totalQuantity} processing`;
        case 'FAILED':
          return 'Failed';
        default:
          return 'Pending';
      }
    case 'Mobilo Metal':
      switch (aiConversionStatus) {
        case 'COMPLETED':
          return 'Completed';
        case 'PROCESSING':
          return `AI file processing`;
        case 'FAILED':
          return 'Failed';
        default:
          return 'Pending';
      }
    case 'Mobilo Wood':
      switch (cardGenerationStatus) {
        case 'COMPLETED':
          switch (aiConversionStatus) {
            case 'COMPLETED':
              return 'Completed';
            case 'PROCESSING':
              return `AI file processing`;
            case 'FAILED':
              return 'Failed';
            default:
              return 'Pending';
          }
        case 'PROCESSING':
          return `${cardGenerationCount}/${totalQuantity} processing`;
        case 'FAILED':
          return 'Failed';
        default:
          break;
      }
  }
};
