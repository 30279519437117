import React, { useState, useContext, useRef, useEffect } from 'react';
import { useParams, Link, withRouter, useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import {
  Typography,
  Button,
  Card,
  Space,
  Image,
  Input,
  Alert,
  message as antdMessage,
  Badge,
  Tabs,
  Empty,
  Row,
  Tag
} from 'antd';
import { firebase } from '../../../../firebase';
import { LeftOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../../Auth';
import Modal from 'antd/lib/modal/Modal';
import PreviousDesignModal from './PreviousDesignModal';
import Loader from '../../../Loader/Loader';
import { catchErrorInSentry } from '../../../../common/utils';

const { TabPane } = Tabs;

function DesignInProgressSet() {
  let { id } = useParams();
  const history = useHistory();
  const [set, setSet] = useState({});
  const [activeTab, setActiveTab] = useState('0');
  const [chat, setChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [template, setTemplate] = useState(false);
  const [preview, setPreview] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [bulkGenerationStatus, setBulkGenerationStatus] = useState();
  const [message, setMessage] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const templateKeys = [
    'template_a',
    'template_b',
    'template_c',
    'template_d',
    'template_e'
  ];
  const getTemplate = (templateCode) => {
    switch (templateCode) {
      case 'template_a':
        return '/templates/card_template1.png';
      case 'template_b':
        return '/templates/card_template2.png';
      case 'template_c':
        return '/templates/card_template3.png';
      case 'template_d':
        return '/templates/card_template4.png';
      default:
        return '/templates/card_template5.png';
    }
  };
  const ref = firebase
    .firestore()
    .collection('sets')
    .where('setUniqueId', '==', id);
  const chatRef = firebase.firestore().collection('chats');
  const { data } = useContext(AuthContext);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef?.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  function getSet() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
      const updatedCardUsers = [];
      querySnapshot.docs[0].data().cardUsers.map((user, index) => {
        updatedCardUsers.push({
          ...user,
          employeeUniqueId: `${
            querySnapshot?.docs[0]?.data()?.setUniqueId
          }_${index}`
        });
      });
      setSet({
        ...querySnapshot?.docs[0]?.data(),
        cardUsers: updatedCardUsers
      });
    });
    setLoading(false);
  }
  function getChat() {
    setLoading(true);
    chatRef
      .doc(id)
      .collection('messages')
      .orderBy('createdAt', 'asc')
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((chat) => {
          items.push(chat?.data());
        });
        setChat(items);
        setLoading(false);
        scrollToBottom();
      });
  }
  function handleSend(value) {
    setMessageLoading(true);
    chatRef
      .doc(id)
      .collection('messages')
      .add({
        action: 'NO_ACTION_TAKEN',
        actionRequired: false,
        message: value,
        orderUniqueId: set?.orderUniqueId,
        senderId: data?.userId,
        senderName: data?.fullName,
        setUniqueId: set?.setUniqueId,
        createdAt: new Date()
      })
      .then((ref) => {
        chatRef
          .doc(id)
          .collection('messages')
          .doc(ref?.id)
          .set(
            {
              docId: ref?.id
            },
            { merge: true }
          )
          .then(() => {
            setMessageLoading(false);
            antdMessage.success('Message sent successfully');
          });
      });
  }
  const getBulkGenerationStatus = async () => {
    try {
      const orderData = await firebase
        .firestore()
        .collection('orders')
        .where('orderUniqueId', '==', set?.orderUniqueId)
        .get();
      setBulkGenerationStatus(
        orderData?.docs[0]?.data()?.bulkCardGenerationStatus
      );
    } catch (error) {
      catchErrorInSentry(error);
      console.log(error);
    }
  };
  useEffect(() => {
    getSet();
    getChat();
  }, []);
  useEffect(() => {
    if (set?.orderUniqueId) {
      getBulkGenerationStatus();
    }
  }, [set]);

  if (loading) {
    return <Loader />;
  }

  const handlePreview = (url) => {
    setPreviewUrl(url);
    setPreview(true);
  };
  return (
    <Card
      title={
        <Space>
          <Button
            onClick={() => {
              history.goBack();
            }}
            type="text"
            icon={<LeftOutlined />}
          />
          <Typography.Title style={{ margin: 0 }} level={3}>
            {id}
          </Typography.Title>
          {bulkGenerationStatus && (
            <Tag className="order-status-tag">
              Bulk Card Generation Status - {bulkGenerationStatus}
            </Tag>
          )}
        </Space>
      }
    >
      <Card title="Employee details" size="small">
        <Tabs
          type="card"
          activeKey={activeTab}
          onTabClick={(key) => setActiveTab(key)}
        >
          {set?.cardUsers
            ? set.cardUsers.map((user, index) => {
                return (
                  <TabPane
                    tab={
                      <Typography.Text strong>
                        {user?.firstName} {user?.lastName}
                      </Typography.Text>
                    }
                    key={index}
                  >
                    <Space direction="vertical">
                      <Space>
                        <Typography.Text strong>Name :</Typography.Text>
                        <Typography.Text>
                          {user?.firstName} {user?.lastName}
                        </Typography.Text>
                      </Space>
                      <Space>
                        <Typography.Text strong>Email :</Typography.Text>
                        <Typography.Text>{user?.email}</Typography.Text>
                      </Space>
                      <Space>
                        <Typography.Text strong>
                          Phone Number (mobile) :
                        </Typography.Text>
                        <Typography.Text>{user?.mobilePhone}</Typography.Text>
                      </Space>
                      <Space>
                        <Typography.Text strong>
                          Phone Number (work) :
                        </Typography.Text>
                        <Typography.Text>{user?.workPhone}</Typography.Text>
                      </Space>
                      <Space>
                        <Typography.Text strong>Company :</Typography.Text>
                        <Typography.Text>{user?.company}</Typography.Text>
                      </Space>
                      <Space>
                        <Typography.Text strong>Title :</Typography.Text>
                        <Typography.Text>{user?.title}</Typography.Text>
                      </Space>
                      {set?.cardType === 'Mobilo Metal (promo)' ||
                      set?.cardType === 'Mobilo Wood' ||
                      set?.cardType === 'Mobilo Metal' ? (
                        <Space>
                          <Typography.Text strong>Card color:</Typography.Text>
                          {(user?.cardColor || set?.cardColor) && (
                            <div
                              style={{
                                background: user?.cardColor || set?.cardColor,
                                height: 15,
                                width: 15
                              }}
                            />
                          )}
                          <Typography.Text>
                            {user?.cardColor || set?.cardColor}
                          </Typography.Text>
                        </Space>
                      ) : null}
                    </Space>
                  </TabPane>
                );
              })
            : null}
        </Tabs>
      </Card>
      <Card
        size="small"
        title={
          <Row justify="space-between" align="middle">
            <Badge dot={set?.hasReferences} offset={[5, 0]}>
              Reference Files
            </Badge>
            {set?.previousDesign && (
              <Button type="primary" onClick={() => setVisible(true)}>
                Previous Design
              </Button>
            )}
            {set?.template && templateKeys?.includes(set?.template) && (
              <Button type="primary" onClick={() => setTemplate(true)}>
                View Template
              </Button>
            )}
          </Row>
        }
      >
        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab="Front Design" key="1">
            {set?.frontImage?.length > 0 ? (
              <Space direction="vertical">
                {set.frontImage.map((item, index) => {
                  try {
                    return item.match(/images([^?]*)/) ? (
                      <a target="_blank" href={item} key={index}>
                        {item
                          .match(/images([^?]*)/)[1]
                          ?.replaceAll('%20', ' ')
                          ?.replaceAll('%2F', '')
                          ?.substr(8)}
                      </a>
                    ) : null;
                  } catch {}
                })}
              </Space>
            ) : (
              <Empty />
            )}
          </TabPane>
          <TabPane tab="Back Design" key="2">
            {set?.backImage?.length > 0 ? (
              <Space direction="vertical">
                {set.backImage.map((item, index) => {
                  try {
                    return item?.match(/images([^?]*)/) ? (
                      <a target="_blank" key={index} href={item}>
                        {item
                          ?.match(/images([^?]*)/)[1]
                          ?.replaceAll('%20', ' ')
                          ?.replaceAll('%2F', '')
                          ?.substr(8)}
                      </a>
                    ) : null;
                  } catch {}
                })}
              </Space>
            ) : (
              <Empty />
            )}
          </TabPane>
          {set?.logoImage && (
            <TabPane tab="Logo Image" key="3">
              {set?.logoImage?.length > 0 ? (
                <Space direction="vertical">
                  {set.logoImage.map((item, index) => {
                    try {
                      return item?.match(/images([^?]*)/) ? (
                        <a target="_blank" href={item} key={index}>
                          {item
                            ?.match(/images([^?]*)/)[1]
                            ?.replaceAll('%20', ' ')
                            ?.replaceAll('%2F', '')
                            ?.substr(8)}
                        </a>
                      ) : null;
                    } catch {}
                  })}
                </Space>
              ) : (
                <Empty />
              )}
            </TabPane>
          )}
        </Tabs>
      </Card>
      <Card size="small" title="Customer's Note">
        {set?.notes?.length ? (
          <Alert message={set?.notes} type="info" />
        ) : (
          <Alert message="No notes found" type="warning" />
        )}
      </Card>
      <Card
        title="Chat"
        size="small"
        actions={[
          <div style={{ padding: '0px 2rem' }}>
            <Input.Search
              placeholder="Enter Message"
              enterButton={
                <Button type="primary" loading={messageLoading}>
                  Send
                </Button>
              }
              size="large"
              value={message}
              onChange={(e) => setMessage(e?.target?.value)}
              onSearch={(value) => {
                handleSend(value);
                setMessage('');
              }}
            />
          </div>
        ]}
        extra={
          set?.status === 'DESIGN_IN_PROGRESS' ? (
            <Link
              to={{
                pathname: `/design/sets/addDesign/${set?.setUniqueId}`,
                set
              }}
            >
              <Button type="primary">Add Design</Button>
            </Link>
          ) : null
        }
      >
        <div style={{ maxHeight: 300, overflowY: 'auto' }}>
          <Space direction="vertical" size="large">
            {chat?.length
              ? chat.map((value, index) => {
                  const date = new Date(value?.createdAt?.seconds * 1000);
                  const day = date.getDate();
                  const month = date.getMonth() + 1;
                  const year = date.getFullYear();
                  const hours = date.getHours();
                  const minutes = date.getMinutes();
                  return (
                    <Space direction="vertical" key={index}>
                      <Space size="middle">
                        <Typography.Title level={5} style={{ margin: 0 }}>
                          {value?.senderName}
                        </Typography.Title>
                        <Typography.Text>{`${day}/${month}/${year} ${hours}:${minutes}`}</Typography.Text>
                      </Space>
                      <Typography.Text>{value?.message}</Typography.Text>
                      {_get(
                        value,
                        `designSet.cardDesign[${activeTab}].digitalCardPreviewUrl`
                      ) && (
                        <Button
                          onClick={() =>
                            handlePreview(
                              _get(
                                value,
                                `designSet.cardDesign[${activeTab}].digitalCardPreviewUrl`
                              )
                            )
                          }
                        >
                          Preview
                        </Button>
                      )}
                      {value?.actionRequired ? (
                        <Tabs type="card">
                          {value?.designSet?.cardDesign?.map(
                            (singleDesign, index) => {
                              const frontImgSrc =
                                (singleDesign?.qrReferenece !== '' ||
                                  singleDesign?.qrReference !== null) &&
                                singleDesign?.cardUser?.cardDesignTemplateId
                                  ? singleDesign?.cardUser?.qrSide === 'FRONT'
                                    ? singleDesign?.qrReference
                                    : singleDesign?.frontImageUrl
                                  : value?.designSet?.qrSide === 'FRONT'
                                  ? singleDesign?.qrReference
                                  : singleDesign?.frontImageUrl;
                              const backImgSrc =
                                (singleDesign?.qrReferenece !== '' ||
                                  singleDesign?.qrReference !== null) &&
                                singleDesign?.cardUser?.cardDesignTemplateId
                                  ? singleDesign?.cardUser?.qrSide === 'BACK'
                                    ? singleDesign?.qrReference
                                    : singleDesign?.backImageUrl
                                  : value?.designSet?.qrSide === 'BACK'
                                  ? singleDesign?.qrReference
                                  : singleDesign?.backImageUrl;
                              return (
                                <TabPane
                                  tab={singleDesign?.cardUser?.email}
                                  key={index}
                                >
                                  <Space>
                                    <Image
                                      alt=""
                                      style={{ maxHeight: 200, maxWidth: 300 }}
                                      src={frontImgSrc}
                                    />
                                    <Image
                                      alt=""
                                      style={{ maxHeight: 200, maxWidth: 300 }}
                                      src={backImgSrc}
                                    />
                                  </Space>
                                </TabPane>
                              );
                            }
                          )}
                        </Tabs>
                      ) : null}
                    </Space>
                  );
                })
              : null}
          </Space>
          <div ref={messagesEndRef} />{' '}
        </div>
      </Card>
      <PreviousDesignModal
        visible={visible}
        onCancel={() => setVisible(false)}
        designId={set?.previousDesign}
      />
      <Modal
        title="Preview"
        visible={preview}
        footer={null}
        onCancel={() => {
          setPreview(false);
        }}
        width={1024}
        bodyStyle={{ height: '75vh', padding: 0 }}
        centered
      >
        <iframe
          src={previewUrl}
          frameBorder="0"
          style={{ height: '100%', width: '100%' }}
        >
          File is corrupted
        </iframe>
      </Modal>
      <Modal
        title="Template"
        visible={template}
        footer={null}
        onCancel={() => {
          setTemplate(false);
        }}
        centered
      >
        <Image
          style={{ maxHeight: 200, maxWidth: 300 }}
          src={getTemplate(set?.template)}
        />
      </Modal>
    </Card>
  );
}

export default withRouter(DesignInProgressSet);
